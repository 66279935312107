.container {
  position: relative;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.logo {
  width: 512px;
  max-width: 80%;
  filter: invert(100%);
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000090;
}

.background {
  width: 100%;
  max-height: 440px;
  object-fit: cover;
}