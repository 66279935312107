a {
  color: #22405E;
}

.button {
  display: inline-block;
  align-items: center;
  height: 40px;
  padding: 1px 16px;
  border-radius: 8px;
  color: white;
  background-color: #22405E;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  font-size: 1.25em;
}
