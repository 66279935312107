.section {
  width: 100%;
  max-width: 1024px;
}

.title {
  margin-top: 0;
  margin-bottom: 40px;
  color: #22405E;
  font-size: 2em;
  font-weight: 700;
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 1.75em;
  }
}

.subtitle {
  margin-top: -30px;
  margin-bottom: 40px;
  color: gray;
  font-size: 1.1em;
  font-style: italic;
}

p {
  font-size: 1.2em;
  line-height: 150%;
}

@media only screen and (max-width: 400px) {
  p {
    font-size: 1.1em;
  }
}
