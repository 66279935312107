.container {
  height: 100%;
  background-color: #EEEEEE;
}

.image {
  height: 100%;
  object-fit: contain;
}
  
.labelContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.label {
  margin: 0 12px 36px;
  padding: 5px 12px;
  color: white;
  font-size: 1em;
  background-color: #00000080;
  border-radius:8px;
}
