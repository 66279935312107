.content {
  margin: 40px 64px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 400px) {
  .content {
    margin: 20px 32px 32px;
  }
}
