.vision {
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  text-align: center;
  font-style: italic;
  font-weight: 300;
  font-size: 1.4em;
}

@media only screen and (max-width: 400px) {
  .vision {
    max-width: 100%;
    font-size: 1.3em;
  }
}

.separator {
  height: 64px;
  margin: 24px 0 32px;
  filter: invert(80%);
}

@media only screen and (max-width: 400px) {
  .separator {
    margin: 12px 0 16px;
  }
}

.prices {
  margin-left: 8px;
  margin-bottom: 32px;
  border-spacing: 20px;
}

.price {
  padding-left: 20px;
  text-align: right;
  font-weight: 200;
}

.subtitle {
  margin-top: 6px;
  color: gray;
  font-size: 0.9em;
  font-style: italic;
}

@media only screen and (max-width: 400px) {
  .subtitle {
    font-size: 0.8em;
  }
}

td {
  font-size: 1.2em;
}

@media only screen and (max-width: 400px) {
  td {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 400px) {
  .carousel {
    margin: 0 -32px 0;
  }
}

.indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 0.1px solid black;
  border-radius: 5px;
  margin: 0 4px;
  background-color: #999999;
}

.indicatorActive {
  background-color: black;
}

.button:not(:first-child) {
  margin-left: 20px;
}

.imprintLink {
  margin-top: 32px;
  font-size: 1em;
  color: #22405E;
  text-decoration: underline;
  text-decoration-color: #22405E;
}
